import React from "react";
import AppBar from './AppBar'
import { Box } from "@mui/material";

function Layout(props) {

   return (
      <Box sx={{ height: '100vh' }}>
         <AppBar companyLogo={props.companyLogo} buildingLogo={props.buildingLogo} />
         <Box sx={{ pt: '100px', height: 'calc(100% - 100px)' }}>
            {props.children}
         </Box>
      </Box>
   )
}

export default Layout