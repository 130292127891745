

const DEV_ENDPOINT = "https://localhost:5001/api/";
const PROD_ENDPOINT = "https://api.wiwi.business/api/";

function GetURL(){
  return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')? DEV_ENDPOINT : PROD_ENDPOINT
}

export default GetURL;
