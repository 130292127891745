import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataService } from '../DataService';

export default function PasscodeDialog(props) {
   const { close, handleSuccess } = props;
   const [passcode, setPasscode] = React.useState('');
   const [showError, setShowError] = React.useState(false);
   React.useEffect(() => {
      if (showError) {
         const interval = setInterval(() => {
            close()
         }, 5000);
         return () => clearInterval(interval);
      }
   }, [showError]);
   function handlePasscodeChange(button) {
      if (passcode.length == 6) {
         setPasscode(button.toString())
      } else if (passcode.length == 5) {
         let pass = `${passcode}${button.toString()}`;
         var id = localStorage.getItem("screenId");
         if (id) {
            DataService.get(`GetEmployeeId?screenId=${id}&pin=${pass}`).then(res => {
               if (res.data.success) {
                  handleSuccess(res.data.data)
                  close()
               } else {
                  setPasscode('')
                  setShowError(true);
               }
            })
         }
         // if(pass != "654321"){
         //    setPasscode('')
         //    setShowError(true);
         // }else{
         //    handleSuccess();
         //    close();
         // }
      } else {
         setPasscode(`${passcode}${button.toString()}`)
      }

   }
   return (
      <Dialog onClose={close} open={true} fullWidth maxWidth="sm">
         {!showError &&
            <div dir='ltr' style={{ ...displayFlex, marginBottom: '60px', marginTop: '100px', }}>
               <Grid spacing={1} sx={{ width: '300px' }} container justifyContent="center" alignItems="center">
                  <input value={passcode} type="password" disabled placeholder='הכנס את הקוד האישי' className='PasscodeInputField' />
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(1)}>
                        <img height='100%' src='/images/icons/numbers/icons-1.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(2)}>
                        <img height='100%' src='/images/icons/numbers/icons-2.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(3)}>
                        <img height='100%' src='/images/icons/numbers/icons-3.png' />
                     </Contianer>
                  </Grid>


                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(4)}>
                        <img height='100%' src='/images/icons/numbers/icons-4.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(5)}>
                        <img height='100%' src='/images/icons/numbers/icons-5.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(6)}>
                        <img height='100%' src='/images/icons/numbers/icons-6.png' />
                     </Contianer>
                  </Grid>


                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(7)}>
                        <img height='100%' src='/images/icons/numbers/icons-7.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(8)}>
                        <img height='100%' src='/images/icons/numbers/icons-8.png' />
                     </Contianer>
                  </Grid>
                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(9)}>
                        <img height='100%' src='/images/icons/numbers/icons-9.png' />
                     </Contianer>
                  </Grid>

                  <Grid xs={4} item sx={displayFlex}>
                     <Contianer onClick={() => handlePasscodeChange(0)}>
                        <img height='100%' src='/images/icons/numbers/icons-0.png' />
                     </Contianer>
                  </Grid>
               </Grid>
            </div>
         }
         {showError &&
            <div style={{ ...displayFlex, flexDirection: 'column', marginTop: '100px', marginBottom: '100px' }}>
               <img height='100px' src='/images/icons/icons-error.png' />
               <Typography color="primary" variant='h4' sx={{ fontWeight: 600, mt: '25px' }} >משתמש לא קיים</Typography>
            </div>
         }
      </Dialog>
   );
}

const Contianer = styled('div')({
   height: '70px',
   width: '70px',
   padding: '10px',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   cursor: 'pointer',
   borderRadius: '20px',
   '&:hover': {
      background: "#e8e9eb",
   }
});

const displayFlex = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
