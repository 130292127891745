
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';

export default function ErrorDialog(props) {
   const { close, message } = props;

   return (
      <Dialog onClose={close} open={true} fullWidth maxWidth="sm">
         <div style={{ ...displayFlex, flexDirection: 'column', marginTop: '100px', marginBottom: '100px' }}>
            <img height='100px' src='/images/icons/icons-error.png' />
            <Typography color="primary" variant='h4' sx={{ fontWeight: 600, mt: '25px' }} >{message}</Typography>
         </div>
      </Dialog>
   );
}

const displayFlex = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
