import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import './App.css';
import Index from './Pages'
import Layout from './Layout';
import i18next from './i18n'

function App() {
  const [companyLogo, setCompanyLogo] = useState('/images/logo_1.png')
  const [buildingLogo, setBuildingLogo] = useState('/images/buildingLogo_1.png')

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Layout companyLogo={companyLogo} buildingLogo={buildingLogo}>
          <Router>
            <Routes>
              <Route exact path="/:id" element={<Index setCompanyLogo={setCompanyLogo} setBuildingLogo={setBuildingLogo} />} />
            </Routes>
          </Router>
        </Layout>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;


const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Heebo, sans-serif'
  },
  palette: {
    primary: {
      light: '#4f5b62',
      main: '#2e323e',
      dark: '#000a12',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffff',
      main: '#cfd8dc',
      dark: '#9ea7aa',
      contrastText: '#000',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255 255 255 / 75%)'
        }
      }
    }
  }
});
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});