import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

// const StyledDialog = styled(Dialog)`
//   .MuiBackdrop-root {
//     background-color: rgb(255 255 255 / 75%);
//   }
// `;

export default function UserDialog(props) {
   const { close } = props;
   React.useEffect(() => {
      const interval = setInterval(() => {
         close()
      }, 5000);
      return () => clearInterval(interval);
   }, []);
   return (
      <Dialog onClose={close} open={true} fullWidth maxWidth="sm">
         <div style={{ ...displayFlex, flexDirection: 'column', marginTop: '100px', marginBottom: '100px' }}>
            <img height='100px' src='/images/icons/icons-success.png' />
            <Typography color="primary" variant='h4' sx={{ fontWeight: 600, mt: '25px' }} >תודה על שיתוף הפעולה!</Typography>
         </div>
      </Dialog>
   );
}


const displayFlex = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
