import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataService } from '../../Components/DataService';
import ErrorDialog from '../ErrorDialog';

export default function MenuDialog(props) {
   const { close, employee } = props;
   const [errorMessage, setErrorMessage] = React.useState(null);
   function RegisterVisit(dutyType, visitType) {
      var id = localStorage.getItem("screenId");
      if (id) {
         DataService.post(`employeeVisits?screenId=${id}&employeeId=${employee}&dutyType=${dutyType}&visitType=${visitType}`).then(res => {
            if (res.data.success) {
               close();
            } else if (res.data.success === false) {
               setErrorMessage(res.data.message);
            }
         })
      }

   }

   return (
      <Dialog onClose={close} open={true} fullWidth maxWidth="md">
         {errorMessage && <ErrorDialog message={errorMessage} close={() => setErrorMessage(null)} />}
         <div style={{ ...displayFlex, marginBottom: '60px', marginTop: '100px' }}>
            <Grid spacing={5} sx={{ width: '450px' }} container justifyContent="center" alignItems="center">
               <Typography color="primary" variant='h4' sx={{ fontWeight: 600, mt: '25px' }} >היי משתמש, בחר פעולה לביצוע.</Typography>
               {menu.map((x, i) =>
                  <Grid xs={6} item sx={displayFlex} k={i}>
                     <Contianer onClick={() => RegisterVisit(x.dutyType, x.visitType)}>
                        <img height='88' src={x.icon} />
                        <Typography variant='h6'>{x.title}</Typography>
                     </Contianer>
                  </Grid>
               )}
            </Grid>
         </div>
      </Dialog>
   );
}
const menu = [
   {
      dutyType: 2,
      visitType: 2,
      icon: '/images/icons/staff/icons-1.png',
      title: "אחזקה"
   },
   {
      dutyType: 1,
      visitType: 2,
      icon: '/images/icons/staff/icons-2.png',
      title: "ניקיון"
   },
   {
      dutyType: 3,
      visitType: 2,
      icon: '/images/icons/staff/icons-3.png',
      title: "מפקח"
   },
   {
      dutyType: 1,
      visitType: 1,
      icon: '/images/icons/staff/icons-4.png',
      title: "ניקיתי"
   }
]

const Contianer = styled('div')({
   height: '120px',
   width: '120px',
   padding: '10px',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   cursor: 'pointer',
   flexDirection: 'column',
   borderRadius: '20px',
   '&:hover': {
      background: "#e8e9eb",
   }
});

const displayFlex = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
