import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Select, MenuItem, ListItemText, ListItemIcon, Avatar } from '@mui/material';
import PasscodeDialog from '../Components/Passcode'
import StaffDialog from '../Components/Staff/MenuDialog'
import { useTranslation } from 'react-i18next';
export default function CustomAppBar(props) {

   const [openPasscodeDialog, setOpenPasscodeDialog] = React.useState(false);
   const [employee, setEmployee] = React.useState(null);
   const { i18n } = useTranslation();
   return (
      <AppBar sx={{height: 100}} dir='ltr' color="primary" position="fixed">
         {openPasscodeDialog && <PasscodeDialog handleSuccess={(d) => setEmployee(d)} close={() => setOpenPasscodeDialog(false)} />}
         {employee && <StaffDialog close={() => setEmployee(null)} employee={employee} />}
         <Toolbar sx={{height: 100}}>
            <img onClick={() => setOpenPasscodeDialog(true)} style={{ cursor: 'pointer', paddingLeft: '5px', paddingRight: '5px' }} height="40%" src={props.companyLogo} />
            <img onClick={() => setOpenPasscodeDialog(true)} style={{ cursor: 'pointer', paddingLeft: '5px', paddingRight: '5px' }} height="40%" src={props.buildingLogo} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

            </Typography>
            <Select
               onChange={(e) => {i18n.changeLanguage(e.target.value); localStorage.setItem("lng", e.target.value)}}
               value={i18n.language} variant="standard" sx={{
                  color: 'white',
                  '& .MuiSelect-icon': {
                     color: 'white',
                  }
               }}
               renderValue={(v) => <Avatar sx={{ width: 64, height: 64 }} alt={v} src={`/images/${v}.png`} />}
               disableUnderline>
               <MenuItem value={'en'}>
                  <ListItemIcon sx={{pr: 2}}><img height="40" src="/images/en.png" /></ListItemIcon>
                  <ListItemText primaryTypographyProps={{variant: 'h5'}}>English</ListItemText>
               </MenuItem>
               <MenuItem value={'he'}>
                  <ListItemIcon sx={{pr: 2}}><img height="40" src="/images/he.png" /></ListItemIcon>
                  <ListItemText primaryTypographyProps={{variant: 'h5'}}>עברית</ListItemText>
               </MenuItem>
            </Select>
         </Toolbar>
      </AppBar>
   );
}