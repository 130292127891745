import * as React from 'react';
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Divider, Typography } from '@mui/material';
import UserDialog from '../Components/User/Dialog';
import { DataService } from '../Components/DataService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import GetURL from '../config'

const API_ENDPOINT = GetURL();

const Item = styled(Grid)(({ theme }) => ({
   textAlign: 'center',
   width: '100%',
   maxHeight: 200,
   padding: 25,
   borderRadius: 10,
   height: 'auto',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   flexDirection: 'column',
   cursor: 'pointer',
   '&:hover': {
     // background: "#e8e9eb",
   }

}));
// const menu = [
//    {
//       icon: '/images/icons/icons-01.png',
//       title: 'בעיית מיזוג אוויר',
//       placement: 'left'
//    },
//    {
//       icon: '/images/icons/icons-02.png',
//       title: "בעיית תאורה",
//       placement: 'left'
//    },
//    {
//       icon: '/images/icons/icons-03.png',
//       title: "בעיה בדלת",
//       placement: 'left'
//    },
//    {
//       icon: '/images/icons/icons-04.png',
//       title: "נזילה",
//       placement: 'left'
//    },
//    {
//       icon: '/images/icons/icons-05.png',
//       title: "מראה",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-06.png',
//       title: "מדרגות",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-07.png',
//       title: "אסלה",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-08.png',
//       title: "משתנה",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-09.png',
//       title: "מקלחות",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-10.png',
//       title: "כיור",
//       placement: 'center'
//    },
//    {
//       icon: '/images/icons/icons-11.png',
//       title: "ריח לא טוב",
//       placement: 'right'
//    },
//    {
//       icon: '/images/icons/icons-12.png',
//       title: "רצפה מלוכלכת",
//       placement: 'right'
//    },
//    {
//       icon: '/images/icons/icons-13.png',
//       title: "חסר נייר טואלט",
//       placement: 'right'
//    },
//    {
//       icon: '/images/icons/icons-14.png',
//       title: "פח מלא",
//       placement: 'right'
//    }
//]
export default function Menu(props) {
   const { setCompanyLogo, setBuildingLogo } = props;
   const [screenData, setScreenData] = useState({});
   const { i18n } = useTranslation();
   const haveRightOrLeftIcons = React.useMemo(() => {
      return screenData?.menus?.filter(x => x.placement === "right").length > 0 || screenData?.menus?.filter(x => x.placement === "left").length > 0
   }, [screenData])
   const counts = React.useMemo(() => {
      return { right: screenData?.menus?.filter(x => x.placement === "right").length, left: screenData?.menus?.filter(x => x.placement === "left").length, center: screenData?.menus?.filter(x => x.placement === "center").length }
   }, [screenData])

   let { id } = useParams();

   function GetData() {
      DataService.get(`?screenId=${id}`).then(res => {
         if (res.data.success) {
            localStorage.setItem("screenId", id);
            setScreenData(res.data.data)
            setCompanyLogo(res.data.data.screen.companyLogo)
            setBuildingLogo(res.data.data.screen.buildingLogo)
         }
      })
   }
   React.useEffect(() => {
      if (id) {
         GetData();
         const signalR = new HubConnectionBuilder()
            .withUrl(`${API_ENDPOINT}hub`)
            .withAutomaticReconnect()
            .build();

         signalR.start().then(result => {
            console.log('Websocket Connected!');
            signalR.invoke("JoinGroup", id).catch(err => { console.log(err); });
            signalR.on('Refresh', message => { GetData(); });
         }).catch(e => console.log('Websocket Connection failed: ', e));
         signalR.onreconnected(res => {
            signalR.invoke("JoinGroup", id).catch(err => { console.log(err); });
            GetData();
         });
      }
   }, [])


   const [openDialog, setOpenDialog] = React.useState(false)
   function RegisterComplaint(id) {
      setOpenDialog(true);
      DataService.post(`Complaints?screenIconId=${id}`).then(res => {
         if (res.data.success) {
         }
      })
   }

   return (
      <Grid sx={{ height: '100%' }} container justifyContent="center" alignItems="center">
         {openDialog && <UserDialog close={() => setOpenDialog(false)} />}
         {haveRightOrLeftIcons &&
            <>
               <Grid sx={{ height: '100%' }} item xs={12} sm={2.4} justifyContent="center" alignItems="center" display='flex'>
                  <Grid sx={{ height: '100%' }} container justifyContent="center" alignItems="center" direction="row">
                     {screenData?.menus?.filter(x => x.placement == "right").map((x, i) =>
                        <Item sx={{ height: `${100 / counts.right}%` }} item key={i} onClick={() => RegisterComplaint(x.id)}>
                           <img height="100%" src={x.iconURL} />
                           <Typography variant='h5'>{i18n.language === "en" ? x.titleEN : x.titleHE}</Typography>
                        </Item>
                     )}
                  </Grid>
               </Grid>

               {screenData?.menus?.filter(x => x.placement == "right").length > 0 &&
                  <Divider orientation="vertical" variant="middle" sx={{ height: '75%', display: { xs: 'none', sm: 'block' } }} />
               }
            </>
         }
         <Grid sx={{ height: '100%' }} item xs={12} sm={haveRightOrLeftIcons ? 7.1 : 12} p={5} justifyContent="center" alignItems="center" display='flex' columns={{ md: 3 }}>
            <Grid sx={{ height: '100%' }} container spacing={5} justifyContent="center" alignItems="center">
               <Grid item xs={12}>
                  <Item><Typography color="primary" variant='h2' sx={{ fontWeight: 600, display: { xs: 'none', sm: 'block' } }} >{i18n.language === "en" ? screenData?.screen?.titleEN : screenData?.screen?.titleHE}</Typography></Item>
               </Grid>
               {screenData?.menus?.filter(x => x.placement == "center").map((x, i) =>
                  <Item item xs={12} sm={4} key={i} onClick={() => RegisterComplaint(x.id)}>
                     <img src={x.iconURL} />
                     <Typography variant='h5'>{i18n.language === "en" ? x.titleEN : x.titleHE}</Typography>
                  </Item>
               )}

            </Grid>
         </Grid>
         {haveRightOrLeftIcons &&
            <>
               {screenData?.menus?.filter(x => x.placement === "left").length > 0 &&
                  <Divider orientation="vertical" variant="middle" sx={{ height: '75%', display: { xs: 'none', sm: 'block' } }} />
               }
               <Grid sx={{ height: '100%' }} item xs={12} sm={2.4} justifyContent="center" alignItems="center" display='flex'>
                  <Grid sx={{ height: '100%' }} container justifyContent="center" alignItems="center" direction="row">
                     {screenData?.menus?.filter(x => x.placement === "left").map((x, i) =>
                        <Item sx={{ height: `${100 / counts.left}%` }} item key={i} onClick={() => RegisterComplaint(x.id)}>
                           <img height="100%" src={x.iconURL} />
                           <Typography variant='h5'>{i18n.language === "en" ? x.titleEN : x.titleHE}</Typography>
                        </Item>
                     )}
                  </Grid>
               </Grid>
            </>
         }
      </Grid>
   );
}